export function setupGame() {
  const gameCover = document.getElementById('gameCover');
  const gameIframe = document.getElementById('gameIframe');
  const gameContainer = document.getElementById('gameContainer');
  const playButton = document.getElementById('playButton');
  const gameControls = document.getElementById('gameControls');
  const fullscreenButton = document.getElementById('fullscreenButton');
  const exitFullscreenButton = document.getElementById('exitFullscreenButton');
  const gameSelectCards = document.querySelectorAll('.game-select-card');

  // Game URLs
  const games = {
    'incredibox': 'https://games.sprunki.top/games/sprunki/sprunki-incredibox.html',
    'phase3': 'https://sprunki.org/sprunki-phase-3.embed',
    'phase4': 'https://sprunki.org/sprunki-phase-4.embed'
  };

  let currentGame = 'incredibox';

  // Update game cover images
  const updateGameCover = (game) => {
    const coverImages = {
      'incredibox': 'https://imgur.com/LxNF6hO.jpg',
      'phase3': 'https://imgur.com/T1suE2T.jpg',
      'phase4': 'https://imgur.com/NvaPODx.jpg'
    };
    
    const coverImage = document.querySelector('#gameCover img');
    if (coverImage && coverImages[game]) {
      coverImage.src = coverImages[game];
    }
  };

  // Game selection
  gameSelectCards.forEach(card => {
    card.addEventListener('click', () => {
      const game = card.dataset.game;
      currentGame = game;
      gameSelectCards.forEach(c => c.classList.remove('selected'));
      card.classList.add('selected');
      updateGameCover(game);
      
      if (gameIframe.style.display !== 'none') {
        gameIframe.src = games[game];
      }
    });
  });

  // Play button handler
  if (playButton) {
    playButton.addEventListener('click', () => {
      if (gameCover) gameCover.style.display = 'none';
      if (gameIframe) {
        gameIframe.style.display = 'block';
        gameIframe.src = games[currentGame];
      }
      if (gameControls) gameControls.style.display = 'flex';
    });
  }

  // Fullscreen handlers
  function enterFullscreen(element) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  }

  function exitFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }

  if (fullscreenButton) {
    fullscreenButton.addEventListener('click', () => {
      if (gameContainer) enterFullscreen(gameContainer);
    });
  }

  if (exitFullscreenButton) {
    exitFullscreenButton.addEventListener('click', () => {
      exitFullscreen();
    });
  }

  // Handle fullscreen change
  function updateFullscreenButtons() {
    const isFullscreen = Boolean(
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    );

    if (fullscreenButton) {
      fullscreenButton.style.display = isFullscreen ? 'none' : 'flex';
    }
    if (exitFullscreenButton) {
      exitFullscreenButton.style.display = isFullscreen ? 'flex' : 'none';
    }
  }

  // Fullscreen change event listeners
  document.addEventListener('fullscreenchange', updateFullscreenButtons);
  document.addEventListener('webkitfullscreenchange', updateFullscreenButtons);
  document.addEventListener('mozfullscreenchange', updateFullscreenButtons);
  document.addEventListener('MSFullscreenChange', updateFullscreenButtons);

  // Initialize button states
  updateFullscreenButtons();
}