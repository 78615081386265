import './style.css';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { translations } from './translations';
import { setupGame } from './components/Game';
import { setupHeader } from './components/Header';

// Initialize i18next with language detector
i18next
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    resources: translations,
    lng: 'en', // Force English as default
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
      lookupQuerystring: 'lng',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage', 'cookie']
    }
  }).then(() => {
    // Initial content update
    updateContent();
    updateMetaTags();
  });

// Update all translatable content
function updateContent() {
  document.querySelectorAll('[data-i18n]').forEach(element => {
    const key = element.getAttribute('data-i18n');
    if (key && i18next.exists(key)) {
      element.textContent = i18next.t(key);
    }
  });
}

// Safely update meta tag content
function updateMetaTag(selector, content) {
  const element = document.querySelector(selector);
  if (element) {
    element.setAttribute('content', content);
  }
}

// Update meta tags based on language
function updateMetaTags() {
  const title = i18next.t('meta.title');
  if (document.title !== title) {
    document.title = title;
  }
  
  updateMetaTag('meta[name="description"]', i18next.t('meta.description'));
  updateMetaTag('meta[property="og:title"]', i18next.t('meta.title'));
  updateMetaTag('meta[property="og:description"]', i18next.t('meta.description'));
}

// Setup components when DOM is loaded
document.addEventListener('DOMContentLoaded', () => {
  setupGame();
  setupHeader();
  
  // Set initial language in dropdowns
  const currentLang = i18next.language || 'en';
  const languageSelect = document.getElementById('language-select');
  const languageSelectMobile = document.getElementById('language-select-mobile');
  
  if (languageSelect) languageSelect.value = currentLang;
  if (languageSelectMobile) languageSelectMobile.value = currentLang;

  // Update copyright year
  const copyrightElement = document.getElementById('copyright-year');
  if (copyrightElement) {
    copyrightElement.innerText = new Date().getFullYear();
  }

  // Language switcher event listeners
  ['language-select', 'language-select-mobile'].forEach(id => {
    const element = document.getElementById(id);
    if (element) {
      element.addEventListener('change', (e) => {
        const lang = e.target.value;
        i18next.changeLanguage(lang).then(() => {
          updateContent();
          updateMetaTags();
          
          // Sync other dropdown
          const otherId = id === 'language-select' ? 'language-select-mobile' : 'language-select';
          const otherElement = document.getElementById(otherId);
          if (otherElement) {
            otherElement.value = lang;
          }
        });
      });
    }
  });
});