export const translations = {
  en: {
    translation: {
      meta: {
        title: "Sprunki - Play Incredibox Style Music Games | Sprunki Phase 3 & 4",
        description: "Create amazing music with Sprunki! Play Sprunki Incredibox, Phase 3, and Phase 4. Mix beats, create melodies, and share your musical masterpieces."
      },
      nav: {
        features: "Features",
        games: "Games",
        faq: "FAQ"
      },
      hero: {
        title: "Welcome to Sprunki",
        subtitle: "Create amazing music with interactive characters"
      },
      games: {
        incredibox: "Sprunki Incredibox",
        phase3: "Sprunki Phase 3",
        phase4: "Sprunki Phase 4"
      },
      buttons: {
        play: "Play Now",
        fullscreen: "Full Screen",
        exit_fullscreen: "Exit Full Screen"
      },
      features: {
        title: "Why Choose Sprunki?",
        music: {
          title: "Musical Creation",
          desc: "Create unique beats and melodies with our interactive characters"
        },
        gameplay: {
          title: "Easy to Play",
          desc: "Simple drag and drop interface for all skill levels"
        },
        share: {
          title: "Share Your Music",
          desc: "Record and share your musical creations with the world"
        }
      },
      faq: {
        title: "Frequently Asked Questions",
        q1: "What is Sprunki?",
        a1: "Sprunki is an interactive music creation game inspired by Incredibox. It allows you to create unique musical compositions by mixing different beats, melodies, and effects using animated characters.",
        q2: "What's the difference between Sprunki Phase 3 and Phase 4?",
        a2: "Each phase offers unique characters, sounds, and musical styles. Phase 4 is our latest version with enhanced graphics and new sound combinations, while Phase 3 features its own distinct musical elements and characters.",
        q3: "Do I need to download anything to play Sprunki?",
        a3: "No! Sprunki is a browser-based game that you can play instantly without any downloads. Simply choose your preferred version and start creating music.",
        q4: "Can I play Sprunki on my mobile device?",
        a4: "Yes! Sprunki is fully optimized for mobile devices, allowing you to create music on your smartphone or tablet with the same great experience as desktop.",
        q5: "How do I save my Sprunki creations?",
        a5: "You can record your musical compositions directly in the game and save them to share with friends or listen to later."
      },
      footer: {
        rights: "All rights reserved."
      }
    }
  },
  zh: {
    translation: {
      meta: {
        title: "Sprunki - 玩转音乐创作游戏 | Sprunki第3章和第4章",
        description: "使用Sprunki创作精彩音乐！体验Sprunki Incredibox、第3章和第4章。混音节拍，创作旋律，分享你的音乐杰作。"
      },
      nav: {
        features: "特色",
        games: "游戏",
        faq: "常见问题"
      },
      hero: {
        title: "欢迎来到Sprunki",
        subtitle: "与互动角色一起创作音乐"
      },
      games: {
        incredibox: "Sprunki音乐盒",
        phase3: "Sprunki第3章",
        phase4: "Sprunki第4章"
      },
      buttons: {
        play: "立即开始",
        fullscreen: "全屏",
        exit_fullscreen: "退出全屏"
      },
      features: {
        title: "为什么选择Sprunki？",
        music: {
          title: "音乐创作",
          desc: "通过互动角色创作独特的节拍和旋律"
        },
        gameplay: {
          title: "简单易玩",
          desc: "适合所有水平的简单拖放界面"
        },
        share: {
          title: "分享音乐",
          desc: "录制并与世界分享你的音乐创作"
        }
      },
      faq: {
        title: "常见问题",
        q1: "什么是Sprunki？",
        a1: "Sprunki是一款受Incredibox启发的互动音乐创作游戏。你可以通过混合不同的节拍、旋律和音效，使用动画角色创作独特的音乐作品。",
        q2: "Sprunki第3章和第4章有什么区别？",
        a2: "每个章节都提供独特的角色、声音和音乐风格。第4章是我们的最新版本，具有增强的图形和新的声音组合，而第3章具有其独特的音乐元素和角色。",
        q3: "玩Sprunki需要下载任何东西吗？",
        a3: "不需要！Sprunki是基于浏览器的游戏，无需下载即可立即开始。只需选择你喜欢的版本即可开始创作音乐。",
        q4: "我可以在移动设备上玩Sprunki吗？",
        a4: "可以！Sprunki完全适配移动设备，让你在智能手机或平板电脑上也能获得与桌面端一样出色的音乐创作体验。",
        q5: "如何保存我的Sprunki创作？",
        a5: "你可以直接在游戏中录制音乐作品，保存后可以与朋友分享或稍后收听。"
      },
      footer: {
        rights: "版权所有。"
      }
    }
  },
  ko: {
    translation: {
      meta: {
        title: "Sprunki - 음악 창작 게임 | Sprunki 페이즈 3 & 4",
        description: "Sprunki로 놀라운 음악을 만드세요! Sprunki Incredibox, 페이즈 3, 페이즈 4를 플레이하세요. 비트를 믹스하고, 멜로디를 만들고, 음악 작품을 공유하세요."
      },
      nav: {
        features: "특징",
        games: "게임",
        faq: "자주 묻는 질문"
      },
      hero: {
        title: "Sprunki에 오신 것을 환영합니다",
        subtitle: "인터랙티브 캐릭터와 함께 음악을 만드세요"
      },
      games: {
        incredibox: "Sprunki 인크레디박스",
        phase3: "Sprunki 페이즈 3",
        phase4: "Sprunki 페이즈 4"
      },
      buttons: {
        play: "지금 시작",
        fullscreen: "전체 화면",
        exit_fullscreen: "전체 화면 종료"
      },
      features: {
        title: "Sprunki를 선택하는 이유",
        music: {
          title: "음악 창작",
          desc: "인터랙티브 캐릭터로 독특한 비트와 멜로디 만들기"
        },
        gameplay: {
          title: "쉬운 플레이",
          desc: "모든 수준의 사용자를 위한 간단한 드래그 앤 드롭 인터페이스"
        },
        share: {
          title: "음악 공유",
          desc: "음악 작품을 녹음하고 세상과 공유하세요"
        }
      },
      faq: {
        title: "자주 묻는 질문",
        q1: "Sprunki란 무엇인가요?",
        a1: "Sprunki는 Incredibox에서 영감을 받은 인터랙티브 음악 창작 게임입니다. 애니메이션 캐릭터를 사용하여 다양한 비트, 멜로디, 효과를 믹스하여 독특한 음악을 만들 수 있습니다.",
        q2: "Sprunki 페이즈 3와 페이즈 4의 차이점은 무엇인가요?",
        a2: "각 페이즈는 고유한 캐릭터, 사운드, 음악 스타일을 제공합니다. 페이즈 4는 향상된 그래픽과 새로운 사운드 조합을 갖춘 최신 버전이며, 페이즈 3는 고유한 음악 요소와 캐릭터를 특징으로 합니다.",
        q3: "Sprunki를 플레이하기 위해 다운로드가 필요한가요?",
        a3: "아니요! Sprunki는 브라우저 기반 게임으로 다운로드 없이 바로 플레이할 수 있습니다. 원하는 버전을 선택하고 바로 음악 창작을 시작하세요.",
        q4: "모바일 기기에서 Sprunki를 플레이할 수 있나요?",
        a4: "네! Sprunki는 모바일 기기에 완벽하게 최적화되어 있어 스마트폰이나 태블릿에서도 데스크톱과 동일한 훌륭한 경험을 즐길 수 있습니다.",
        q5: "Sprunki 창작물을 어떻게 저장하나요?",
        a5: "게임에서 직접 음악 작품을 녹음하고 저장하여 친구들과 공유하거나 나중에 들을 수 있습니다."
      },
      footer: {
        rights: "모든 권리 보유."
      }
    }
  },
  es: {
    translation: {
      meta: {
        title: "Sprunki - Juegos de Creación Musical | Sprunki Fase 3 y 4",
        description: "¡Crea música increíble con Sprunki! Juega Sprunki Incredibox, Fase 3 y Fase 4. Mezcla ritmos, crea melodías y comparte tus obras maestras musicales."
      },
      nav: {
        features: "Características",
        games: "Juegos",
        faq: "Preguntas Frecuentes"
      },
      hero: {
        title: "Bienvenido a Sprunki",
        subtitle: "Crea música increíble con personajes interactivos"
      },
      games: {
        incredibox: "Sprunki Incredibox",
        phase3: "Sprunki Fase 3",
        phase4: "Sprunki Fase 4"
      },
      buttons: {
        play: "Jugar Ahora",
        fullscreen: "Pantalla Completa",
        exit_fullscreen: "Salir de Pantalla Completa"
      },
      features: {
        title: "¿Por qué elegir Sprunki?",
        music: {
          title: "Creación Musical",
          desc: "Crea ritmos y melodías únicos con nuestros personajes interactivos"
        },
        gameplay: {
          title: "Fácil de Jugar",
          desc: "Interfaz simple de arrastrar y soltar para todos los niveles"
        },
        share: {
          title: "Comparte tu Música",
          desc: "Graba y comparte tus creaciones musicales con el mundo"
        }
      },
      faq: {
        title: "Preguntas Frecuentes",
        q1: "¿Qué es Sprunki?",
        a1: "Sprunki es un juego interactivo de creación musical inspirado en Incredibox. Te permite crear composiciones musicales únicas mezclando diferentes ritmos, melodías y efectos usando personajes animados.",
        q2: "¿Cuál es la diferencia entre Sprunki Fase 3 y Fase 4?",
        a2: "Cada fase ofrece personajes, sonidos y estilos musicales únicos. La Fase 4 es nuestra última versión con gráficos mejorados y nuevas combinaciones de sonido, mientras que la Fase 3 presenta sus propios elementos musicales y personajes distintivos.",
        q3: "¿Necesito descargar algo para jugar Sprunki?",
        a3: "¡No! Sprunki es un juego basado en navegador que puedes jugar instantáneamente sin descargas. Simplemente elige tu versión preferida y comienza a crear música.",
        q4: "¿Puedo jugar Sprunki en mi dispositivo móvil?",
        a4: "¡Sí! Sprunki está completamente optimizado para dispositivos móviles, permitiéndote crear música en tu smartphone o tablet con la misma gran experiencia que en desktop.",
        q5: "¿Cómo guardo mis creaciones de Sprunki?",
        a5: "Puedes grabar tus composiciones musicales directamente en el juego y guardarlas para compartir con amigos o escuchar más tarde."
      },
      footer: {
        rights: "Todos los derechos reservados."
      }
    }
  }
};